/* eslint-disable react/prop-types */
// import { useState } from 'react';
import AppContext from './app.context';
// import userServices from '@/services/api/affiliate/user.services';
// import localStorageUtils from '@/utils/localStorage.utils';
import useAuthHook from '@/hooks/useAuth.hook';
// import useAuth from '@/features/auth/useAuth';

export default function AppContextProvider(props) {
	useAuthHook();
	// const { loginToken } = useAuth();

	// const [isAffiliateLoading, setIsAffiliateLoading] = useState(false);

	// const [affiliateUserInfo, setAffiliateUserInfo] = useState({});
	// const [affiliateToken, setAffiliateToken] = useState('');

	// useEffect(() => {
	// 	if (localStorageUtils.get('affiliateToken')) {
	// 		VerifyAffiliateToken(localStorageUtils.get('affiliateToken'));
	// 	}
	// }, [loginToken]);

	// const VerifyAffiliateToken = (token) => {
	// 	setIsAffiliateLoading(true);
	// 	userServices
	// 		.GetProfile()
	// 		.then((response) => {
	// 			setAffiliateUserInfo(response.data.data);
	// 			setAffiliateToken(token);
	// 		})
	// 		.catch(() => {
	// 			console.warn('Detected another active session, logging out.');
	// 			affiliateLogout();
	// 		});
	// 	setIsAffiliateLoading(false);
	// };

	// const setNewAffiliateToken = (token) => {
	// 	if (token) {
	// 		localStorage.setItem('affiliateToken', token);
	// 		VerifyAffiliateToken(token);
	// 	}
	// };

	// const affiliateLogout = async () => {
	// 	setIsAffiliateLoading(true);
	// 	setAffiliateToken('');
	// 	setAffiliateUserInfo([]);
	// 	localStorage.removeItem('affiliateToken');
	// 	setIsAffiliateLoading(false);
	// };

	const contextObject = {};

	return <AppContext.Provider value={contextObject}>{props.children}</AppContext.Provider>;
}
