const GetDepoWd = `
	query getDepoWd ($brandId: String!) {
		getDepoWd (brandId: $brandId) {
			report {
				amount
				currency
				currencyLogo
				username
				transactionType
			}
		}
	}
`;

const TransactionQueries = { GetDepoWd };

export default TransactionQueries;
