const appRoutes = {
	HOME: '/',
	REGISTER: '/register/',
	ARTICLE: '/a/',
	PROMOTION: '/promotion/',
	GAMES: '/games/',
	GAMES_ROUTES: {
		// LOTTERY: '/#lottery',
		// CARD: '/#card-game',
		// SPORT: '/#sports',
		LOTTERY: '/games/lottery/',
		CARD: '/games/cards/',
		SPORT: '/games/sports/',
		SLOT: '/games/slots/',
		CASINO: '/games/casino/',
		FISHING: '/games/fishing/',
		COCKFIGHT: '/games/cockfight/',
		OTHER: '/games/others/'
	},
	PROFILE: '/account/profile/information/',
	ACCOUNT_ROUTES: {
		PROFILE: '/account/profile/information/',
		WALLET_ROUTES: {
			HISTORY: {
				BASE: '/account/wallet/history/',
				DEPOWD: '/account/wallet/history/?transactionType=DEPOWD',
				SWAP: '/account/wallet/history/?transactionType=SWAP',
				GAMETRANSFER: '/account/wallet/history/?transactionType=GAMETRANSFER',
				QUERY_NAME: 'transactionType',
				QUERY: {
					DEPOWD: 'DEPOWD',
					SWAP: 'SWAP',
					GAMETRANSFER: 'GAMETRANSFER'
				}
			},
			DEPOSIT: '/account/wallet/deposit/',
			WITHDRAW: '/account/wallet/withdraw/',
			SWAP: '/account/wallet/swap/',
			ACCOUNT: '/account/wallet/account/'
		}
	},
	BETREPORT: '/account/report/',
	REFERRAL: '/account/referral/',
	REWARD: '/account/reward/bonus/',
	// PLAY: '/play/',
	PLAY: {
		INDEX: '/play/',
		GAME: '/play/game/',
		LAUNCH_GAME: '/play/launch-game/'
	},
	PROVIDER: '/providers/',
	WINNING_NUMBERS: '/winning-numbers/',
	FAQ: '/faq/',
	UNKNOWN: '*'
};

export default appRoutes;
