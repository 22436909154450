const GenerateFingerprint = async (checks) => {
	if (!checks) return console.error('At least one check is required!');

	const output = await Promise.all(checks.map((file) => require(`./fingerprints/${file}`)()));

	return output.join('|').toString();
};

const FingerprintHelper = {
	GenerateFingerprint
};

export default FingerprintHelper;
