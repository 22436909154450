'use client';

import { create } from 'zustand';

const useUserStore = create((set) => ({
	user: null,
	setUser: (user) => {
		set((state) => ({ ...state, user: user }));
	},
	clearUser: () => {
		set((state) => ({ ...state, user: null }));
	}
}));

export default useUserStore;
