const walletListToMap = (balance) => {
	const balanceMap = new Map();

	for (const item in balance) {
		balanceMap.set(item, balance[item]);
	}

	return balanceMap;
};

const updateWallet = (existWallet, newWallet) => {
	if (existWallet && newWallet) {
		const existBalance = new Map(existWallet);
		let newBalance = newWallet;

		for (const item in newBalance) {
			const prevData = existBalance.get(item);

			if (prevData && typeof prevData === 'object') {
				existBalance.set(item, { ...prevData, totalBalance: newBalance[item] });
			}
		}

		return existBalance;
	}
};

const updateNetwork = (existWallet, currency, newNetwork) => {
	if (existWallet && currency && newNetwork) {
		const existBalance = new Map(existWallet);

		const prevData = existBalance.get(currency);

		if (prevData && typeof prevData === 'object') {
			const updatedNetworkList = [newNetwork];
			// const updatedNetworkList = Array.isArray(prevData.networkList)
			// 	? [...prevData.networkList, newNetwork]
			// 	: [newNetwork];
			existBalance.set(currency, {
				...prevData,
				networkList: updatedNetworkList
			});
		}

		return existBalance;
	}
};

const formatCurrency = (num, currencyType = '') => {
	// Determine the rounding multiplier based on currencyType
	const multiplier = currencyType === 'fiat' ? 100 : 100000;

	// Round the number based on the currency type
	num = Math.round(num * multiplier) / multiplier;

	// Split the number into its integer and decimal parts
	let [intPart, decPart] = num.toString().split('.');

	// Add a period (.) every 3 digits in the integer part
	intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	// For 'fiat' currencyType, ensure the decimal part is exactly 2 digits if it exists
	if (currencyType === 'fiat' && decPart) {
		decPart = decPart.padEnd(2, '0');
	}

	// Return the formatted number, or just the integer part if no decimal
	return decPart ? `${intPart}.${decPart}` : intPart;
};

const walletUtils = { walletListToMap, updateWallet, updateNetwork, formatCurrency };

export default walletUtils;
