import axios from 'axios';

async function getUser(loginToken) {
	return axios
		.post(`${process.env.BASE_URL}/v1/me`, null, {
			headers: {
				Authorization: loginToken
			}
		})
		.then((response) => response.data)
		.catch(() => {
			return null;
		});
}

const userService = {
	getUser
};

export default userService;
