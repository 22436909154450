import { createWithEqualityFn } from 'zustand/traditional';
// import { GetBrandSetting } from '@/utils/helper';
import brandSettingJson from '../../public/data/brandSettings.json';
// import BrandServices from '@/services/api/brand';
// import localStorageUtils from '@/utils/localStorage.utils';
// import axios from 'axios';

const useBrandConfigStore = createWithEqualityFn((set) => ({
	brandId: brandSettingJson.brandId,
	themeConfig: brandSettingJson.themeConfig,
	// brandConfig:
	config: brandSettingJson,
	languages: brandSettingJson.langOption,
	isLoadingConfig: false,
	errorBrandSettingConfig: null,
	initialBrandSettingConfig: async () => {
		try {
			// const resConfig = await axios.get('/data/brandSettings.json');
			// const resThemeConfig = await axios.get('/data/theme-config.json');
			const brandSetting = brandSettingJson;
			if (brandSetting) {
				set({
					brandId: brandSetting.brandId,
					themeConfig: brandSetting.themeConfig,
					languages: brandSetting.langOption,
					config: brandSetting,
					isLoadingConfig: false
				});
			}
		} catch (error) {
			set({ errorBrandSettingConfig: error });
		}
	}
	// setBrandSetting: (value) => set((state) => (state.config = value)),
	// setSelectedLanguage: (language) => {
	// 	localStorageUtils.set('lang', language || 'id-ID');
	// 	set({ selectedLanguage: language });
	// },
	// getBrandSetting: GetBrandSetting().then((response) => {
	// 	const languageOptions = response.langOption.filter((l) => l.isDisplay);
	// 	set((state) => ({
	// 		...state,
	// 		config: { ...state.config, ...response },
	// 		languages: languageOptions,
	// 		isLoadingConfig: false
	// 	}));
	// }),
	// getBrandSetting2: () =>
	// 	BrandServices.GetBrandSetting({ brandId: BrandSetting.brandId, type: 'dynamic' }).then(
	// 		(response) => {
	// 			set((state) => ({ ...state, config: { ...state.config, ...response } }));
	// 		}
	// 	)
}));

export default useBrandConfigStore;
