import { createContext } from 'react';

const AppContext = createContext({
	// affiliateUserInfo: [],
	// setAffiliateUserInfo: () => {},
	// affiliateToken: null,
	// setAffiliateToken: function () {},
	// affiliateLogout: () => {
	// 	localStorage.removeItem('affiliateToken');
	// },
	// isAffiliateLoading: true,
	// setIsAffiliateLoading: () => {}
});

export default AppContext;
