import axios from 'axios';
import getToken from '../getToken';


export const fetchData = (data, url) => {
	const token = getToken();
	return axios
		.post(`${process.env.BASE_URL}/v1/${url}`, data ?? null, {
			headers: {
				Authorization: token,
			},
		})
		.then(response => response.data)
		.catch(err => {
			throw err?.response;
		});
};

export const fetchDataAuth = (data, token, url) => {
	return axios
		.post(`${process.env.BASE_URL}/v1/${url}`, data ?? null, {
			headers: {
				Authorization: token,
			},
		})
		.then(response => response.data)
		.catch(err => {
			throw err.response;
		});
};

export const fetchDataFormData = (data, url) => {
	const token = getToken();
	return axios
		.post(`${process.env.BASE_URL}/v1/${url}`, data ?? null, {
			headers: {
				Authorization: token,
				'Content-Type': 'multipart/form-data'
			},
		})
		.then(response => response.data)
		.catch(err => {
			throw err.response.data;
		});
};
