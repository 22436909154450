const mutationLogin = `
	mutation MyMutation {
		countLogin{
				message
		}
	}
`;

const CountLogin = {
	mutationLogin,
};

export default CountLogin;
