import axios from 'axios';

const { create } = require('zustand');

// const listOfGame = require('../../public/data/listofgame.json');
// const listOfRecommendedGame = require('../../public/data/listOfRecommendedGame.json');
// const listOfPopularGame = require('../../public/data/listOfPopularGame.json');
// const listOfGameProvider = require('../../public/data/listOfGameProvider.json');
// const listOfGameCategory = require('../../public/data/listOfGameCategory.json');

const useGameStore = create((set) => {
	return {
		allGameData: { body: [], total: 0 } ?? [],
		recommendedGameData: [],
		popularGameData: [],
		gameProviderData: [],
		gameCategoryData: [],
		errorGame: null,
		isLoadingGame: true,
		initialGame: async () => {
			try {
				const resAllGame = await axios.get('/data/listofgame.json');
				const resRecommendedGame = await axios.get('/data/listOfRecommendedGame.json');
				const resPopularGame = await axios.get('/data/listOfPopularGame.json');
				const resGameProvider = await axios.get('/data/listOfGameProvider.json');
				const resGameCategory = await axios.get('/data/listOfGameCategory.json');
				// const resThemeConfig = await axios.get('/data/theme-config.json');
				set({
					allGameData: { body: resAllGame?.data?.body, total: resAllGame?.data?.total },
					recommendedGameData: resRecommendedGame.data,
					popularGameData: resPopularGame.data,
					gameProviderData: resGameProvider.data,
					gameCategoryData: resGameCategory.data,
					isLoadingGame: false
				});
			} catch (error) {
				set({ errorGame: error });
			}
		}
		// gameImageDomainBase: listOfGame.baseUrl,
		// allGameData: { body: listOfGame.body, total: listOfGame.total } ?? [],
		// recommendedGameData: listOfRecommendedGame,
		// popularGameData: listOfPopularGame,
		// gameProviderData: listOfGameProvider,
		// gameCategoryData: listOfGameCategory
		// setAllGameData: () => ({})
	};
});

export default useGameStore;
