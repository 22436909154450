import axios from 'axios';
import getToken from '../../getToken';
import { fetchData } from '../fetchData';

const GetUserInformation = () => {
	const token = getToken();
	return axios
		.post(`${process.env.BASE_URL}/v1/me`, null, {
			headers: {
				Authorization: token
			}
		})
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const UpdateUserInformation = async (payload) => {
	try {
		const response = await fetchData(payload, 'edit-profile');
		return response.data;
	} catch (error) {
		throw error.data;
	}
};

const GetCurrentBalance = async ({ token }) => {
	try {
		const reqBody = {
			brandId: 'COPA',
			username: 'beauty@email.com'
		};

		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/get-balance`, {
				method: 'POST',
				body: JSON.stringify(reqBody),
				headers: {
					Authorization: token
				}
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const GetCurrentBalance2 = async ({ type }) => {
	try {
		const response = await fetchData({ type }, '/get-balance2');

		return response.data;
	} catch (error) {
		return error.data;
	}
};

const getBetReports = async (payload) => {
	try {
		const response = await fetchData(payload, '/bet-report');
	
		return response.data;
	} catch (error) {
		throw error.data;
	}
};

const changeMainWallet = async ({ token, currencyName }) => {
	try {
		const body = {
			selectedWallet: currencyName
		};

		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/changeActiveWallet`, {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					Authorization: token
				}
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const changeMainWallet2 = async ({ token, currencyName }) => {
	try {
		const body = {
			selectedWallet: currencyName
		};

		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/changeActiveWallet2`, {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					Authorization: token
				}
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const Referral = (payload) => {
	axios.defaults.headers.common.Authorization = getToken();
	return axios
		.post(`${process.env.BASE_URL}/v1/referral`, payload)
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const GetReferral = async () => {
	axios.defaults.headers.common.Authorization = getToken();
	return axios
		.post(`${process.env.BASE_URL}/v1/referral/get`)
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const GetReferralPlayer = async (payload) => {
	try {
		const response = await fetchData(payload, 'referral');
		return response.data;
	} catch (error) {
		throw error.data;
	}
};

const UserServices = {
	GetUserInformation,
	UpdateUserInformation,
	GetCurrentBalance,
	GetCurrentBalance2,
	getBetReports,
	changeMainWallet,
	changeMainWallet2,
	Referral,
	GetReferral,
	GetReferralPlayer,
};

export default UserServices;
