const SubscribeBalance = `
  subscription MySubscription ($brandUsername: String!) {
    newBalance(brandUsername: $brandUsername) {
      walletBalance
      activeWallet
      brandUsername
    }
  }
`;

const SubscribeDepo = `
  subscription MySubscription ($brandUsername: String!) {
    playerNotifDepoWd(brandUsername: $brandUsername) {
      amount
      brandUsername
      currency
      transactionStatus
      transactionType
    }
  }
`;

const BalanceSubscription = { SubscribeBalance, SubscribeDepo };

export default BalanceSubscription;
