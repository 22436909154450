/* eslint-disable no-undef */
import { fetchData, fetchDataFormData } from '../fetchData';

const GetAllTransaction = async ({ token, data }) => {
	try {
		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/get-transaction`, {
				method: 'POST',
				headers: {
					Authorization: token
				},
				body: JSON.stringify(data)
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const GetReports = async (payload) => {
	try {
		const response = await fetchData(payload, 'reports');

		return response.data;
	} catch (error) {
		throw error.data;
	}
};

const GetTransactionList = async ({ token, sort, transactionType }) => {
	const data = {
		sort: sort,
		transactionType: transactionType
	};
	try {
		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/listAccountTransaction`, {
				method: 'POST',
				headers: {
					Authorization: token
				},
				body: JSON.stringify(data)
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const GetSwapTransactionList = async ({ token }) => {
	try {
		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/getSwapHistory`, {
				method: 'POST',
				headers: {
					Authorization: token
				}
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const GetExchangeRate = async ({ token, currencyTarget, currencyTo }) => {
	try {
		const data = {
			currency: currencyTarget,
			targetCurrency: currencyTo
		};

		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/getExchangeRate`, {
				method: 'POST',
				headers: {
					Authorization: token
				},
				body: JSON.stringify(data)
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const SwapBalanceUSDToCrypto = async ({ token, chain, amount }) => {
	try {
		const data = {
			chain: chain,
			amount: amount
		};

		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/convertUSDToCrypto`, {
				method: 'POST',
				headers: {
					Authorization: token
				},
				body: JSON.stringify(data)
			})
		)?.json();
		return response;
	} catch (error) {
		return error;
	}
};

const SwapBalanceCryptoToUSD = async ({ token, chain, amount }) => {
	try {
		const data = {
			chain: chain,
			amount: amount
		};

		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/convertCryptoToUSD`, {
				method: 'POST',
				headers: {
					Authorization: token
				},
				body: JSON.stringify(data)
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const SwapBalanceCrypto = async (payload) => {
	try {
		const response = await fetchData(payload, 'swap');
		return response.data;
	} catch (error) {
		throw error.data;
	}
};

const CreateTransaction = async ({ token, data }) => {
	const response = await (
		await fetch(`${process.env.BASE_URL}/v1/depo-wd`, {
			method: 'POST',
			headers: {
				Authorization: token
			},
			body: JSON.stringify(data)
		})
	)?.json();

	return response;
};

const NewCreateTransactionDepoWd = async (payload) => {
	try {
		const response = await fetchDataFormData(payload, 'depowd');
		return response;
	} catch (error) {
		if (error.message.substring(0, 1) === '/') throw error.message.substring(1);
		throw error.message;
	}
};

// const Deposit = async () => {
// 	const result = await API.graphql({
// 		query: TransactionMutation.Transaction,
// 		variables: {
// 			amount: amount,
// 			bank: bank,
// 			transactionType: 'deposit',
// 			username: username
// 		}
// 	});

// 	return result;
// };

// const Withdraw = async () => {
// 	const result = await API.graphql({
// 		query: TransactionMutation.Transaction,
// 		variables: {
// 			amount: amount,
// 			bank: bank,
// 			transactionType: 'withdraw',
// 			username: username
// 		}
// 	});

// 	return result.data.getAllTransaction.transactions;
// };

const GameTransaction = async (payload) => {
	try {
		const response = await fetchData(payload, 'game');
		return response;
	} catch (error) {
		throw error.data;
	}
};

const GameTransactionNoAuth = async (payload) => {
	try {
		const response = await fetchData(payload, 'gameNoAuth');
		return response;
	} catch (error) {
		throw error?.data;
	}
};

const TransactionServices = {
	NewCreateTransactionDepoWd,
	CreateTransaction,
	GetExchangeRate,
	SwapBalanceUSDToCrypto,
	SwapBalanceCryptoToUSD,
	SwapBalanceCrypto,
	GetAllTransaction,
	// Deposit,
	// Withdraw,
	GetTransactionList,
	GetSwapTransactionList,
	GameTransaction,
	GameTransactionNoAuth,
	GetReports
};

export default TransactionServices;
