import useUserStore from './useUserStore';
import userService from './user.service';

export default function useUser() {
	const userStore = useUserStore((state) => state);

	async function setLoggedInUser(loginToken) {
		return userService.getUser(loginToken).then((user) => {
			userStore.setUser(user);
		});
	}

	function logoutLoggedInUser() {
		userStore.clearUser();
	}

	return {
		user: userStore.user,
		setLoggedInUser,
		logoutLoggedInUser
	};
}
