import { apiInstance } from '../apiInstance';

export class UserApiService {
	static async UserInfo(config = {}) {
		const { headers } = config;

		const response = await apiInstance({
			method: 'post',
			url: '/v1/me',
			headers: headers
		});

		return response.data;
	}

	static async UserBalance(config = {}) {
		const { data, headers } = config;

		const response = await apiInstance({
			method: 'post',
			url: '/v1/get-balance2',
			headers: headers,
			data: data
		});

		return response.data;
	}

	static async ChangeActiveWallet(config = {}) {
		const { data, headers } = config;

		const response = await apiInstance({
			method: 'post',
			url: '/v1/changeActiveWallet2',
			headers: headers,
			data: data
		});

		return response.data;
	}

	static async GetCryptoAddress(config = {}) {
		const { data, headers } = config;

		const response = await apiInstance({
			method: 'post',
			url: '/v1/cryptoWallet/getAddress',
			headers,
			data
		});

		return response.data;
	}

	static async CryptoWalletStatus(config = {}) {
		const { data, headers } = config;

		const response = await apiInstance({
			method: 'post',
			url: '/v1/cryptoWallet/isAddressActive',
			headers,
			data
		});

		return response.data;
	}
}
