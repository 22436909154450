const newMap = (data) => {
	if (!data) return;
	const newData = [...new Map(Object.entries(data))].sort();
				
	const json = JSON.stringify(Object.fromEntries(newData));
	const parsedJson = JSON.parse(json);
	
	return parsedJson;
};

const CreateObj = {
	newMap,
};

export default CreateObj;
