// import 'core-js/features/global-this';
// import '@formatjs/intl-locale/polyfill';
// import '@formatjs/intl-numberformat/polyfill';
// import '@formatjs/intl-numberformat/locale-data/en-150';
// import '@formatjs/intl-numberformat/locale-data/id';
// import '@formatjs/intl-numberformat/locale-data/en-GB';
// import '@formatjs/intl-pluralrules/polyfill';
// import '@formatjs/intl-relativetimeformat/polyfill';
// import '@formatjs/intl-listformat/polyfill';
import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';

import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { appWithI18Next } from 'ni18n';
// import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';

import { nexti18nConfig } from '../next-i18next.config';
import useBrandConfigStore from '../context/store/useBrandConfigStore.store';
import { ThemeProvider } from '../context/themeContext.context';
// import AppContextProvider from '@/context/app.context/app.context.provider';
import useAuth from '../src/features/auth/useAuth';
import useGameStore from '../context/store/useGameStore.store';
const Layout = dynamic(() => import('@/components/templates/Layout/Layout'));

import { Amplify } from 'aws-amplify';
import AppContextProvider from '@/context/app.context/app.context.provider';
// import useBrandConfigStore from '@/context/store/useBrandConfigStore.store';
// // import useModalStore from '../src/features/modal/useModalStore';
import ConstantDataStore from '@/context/store/constant-data';
// import { ThemeProvider } from '@/context/themeContext.context';
import usePersistentState from '@/hooks/usePersistentState';
// import useRouterHook from '@/hooks/useRouterHook.hook';
import { useLanguageHook } from '@/hooks/useLanguageHook.hook';
// import { apolloClient } from '../src/features/appsync/apollo-client';
import config from '@/constants/config';
// import useAuth from '@/features/auth/useAuth';

// import BrandSetting from '../public/data/brandSettings.json';
import {
	useAppsyncCountLoginHook,
	useAppsyncSubscriptionWalletBalanceHook,
	useAppsyncSubscriptionDepowd
} from '@/hooks/useAppsyncHook.hook';
import useRouterHook from '../hooks/useRouterHook.hook';
import appRoutes from '../src/core/routes';
// import { LoadingPage } from '../src/components/atom/Loading';

Amplify.configure(config.aws_amplify);

// const customScript

const MyApp = ({ Component, pageProps }) => {
	const { router } = useRouterHook();
	const { brandId, initialBrandSettingConfig } = useBrandConfigStore((state) => ({
		brandId: state.brandId,
		initialBrandSettingConfig: state.initialBrandSettingConfig,
		isLoadingConfig: state.isLoadingConfig
	}));
	const { initialGame } = useGameStore((state) => ({
		// isLoadingGame: state.isLoadingGame,
		initialGame: state.initialGame
	}));

	const { initializeAuthStore, loginToken: token, sessionToken } = useAuth();
	const { getCurrencyList, getBankList /* , getProviders */ } = ConstantDataStore((state) => ({
		getCurrencyList: state.getCurrencyList,
		getBankList: state.getBankList
		// getProviders: state.getProviders
	}));
	const { setPersistentState } = usePersistentState('', 'referralCode');

	useEffect(() => {
		if (!router.asPath.includes(appRoutes.PLAY.LAUNCH_GAME)) {
			if (initialBrandSettingConfig) initialBrandSettingConfig();
			if (initialGame) initialGame();
		}
	}, []);

	useEffect(() => {
		if (!router.asPath.startsWith(appRoutes.PLAY.LAUNCH_GAME) && brandId) initializeAuthStore();
	}, [brandId]);

	useEffect(() => {
		if (
			!router.asPath.startsWith(appRoutes.PLAY.LAUNCH_GAME) &&
			brandId &&
			(token || sessionToken)
		) {
			getCurrencyList({ type: 'get', currencyType: 'all', brandId: brandId });
			// getProviders();
			getBankList();
		}
	}, [brandId, token, sessionToken]);

	useEffect(() => storePathValues(), [router.asPath]);

	useLanguageHook(); // languageπ
	useAppsyncSubscriptionWalletBalanceHook(); // appsync balance subscription
	useAppsyncCountLoginHook(); // appsync count player login
	useAppsyncSubscriptionDepowd(); // appsync subscription depowd

	const storePathValues = () => {
		const storage = globalThis?.sessionStorage;
		if (!storage) return;
		const prevPath = storage.getItem('currentPath');
		storage.setItem('prevPath', prevPath ? prevPath : '/');
		storage.setItem('currentPath', globalThis.location.pathname);
	};

	useEffect(() => {
		const isRegister = 'register' in router.query;
		const isLogin = token && token?.split(':')[0].toLowerCase() !== 'sessiontoken';
		if (isRegister && router.query.register && !isLogin) {
			const code = router.query.referralCode;
			router.push('/register');

			if (code) {
				setPersistentState(code);
			}

			delete router.query;
			router.push(router);
		} else if (isRegister && router.query.register && isLogin) {
			delete router.query;
			router.push(router);
		}
	}, [router]);

	// Remove later
	// useEffect(() => {
	// 	const handlePostMessage = (event) => {
	// 		if (event.data?.name === 'gameQuit') {
	// 			routerGoToPage({ url: '/' });
	// 		}
	// 	};

	// 	window.addEventListener('message', handlePostMessage);

	// 	return () => {
	// 		window.removeEventListener('message', handlePostMessage);
	// 	};
	// }, []);

	// eslint-disable-next-line no-const-assign
	// pageProps = {
	// 	...pageProps,
	// 	brandConfig, //remove later
	// 	brandId: BrandSetting.brandId
	// 	// isLoadingConfig, // remove later
	// 	// theme
	// };

	// if (!router.asPath.includes(appRoutes.PLAY.LAUNCH_GAME) && isLoadingConfig) {
	// 	return <LoadingPage />;
	// }

	if (router.asPath.startsWith(appRoutes.PLAY.LAUNCH_GAME)) {
		return <Component {...pageProps} />;
	}

	return (
		<>
			{/* toastify */}
			<ToastContainer
				position="top-right"
				autoClose={1000 * 5}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
			/>
			<ThemeProvider>
				{/* <ApolloProvider client={apolloClient}> */}
				<AppContextProvider>
					<Layout>
						<Component {...pageProps} />
					</Layout>
				</AppContextProvider>
				{/* </ApolloProvider> */}
			</ThemeProvider>
		</>
	);
};

// MyApp.getInitialProps = async (appContext) => {
// 	const pageProps = App.getInitialProps(appContext);
// 	const { req, res } = appContext.ctx;
// 	const host = req.headers['x-forwarded-host'] || req.headers['host'];
// 	console.log(host);
// 	if (res) {
// 		res.setHeader('Cache-Control', 'public, max-age=10');
// 		res.setHeader('My-Header', 'My-Value');
// 	}
// 	// console.log(res.setHeader('Cache-Control', 'My-Cache-Control'));
// 	// const res = await fetch(`${host}/data/brandSettings.json`);
// 	// console.log('res : ', res);
// 	// const cookies = parseCookies(req);
// 	console.log('jalan gk ');

// 	return { ...pageProps, brandSetting: { brandId: 'COPA' } };
// };

// MyApp.getInitialProps = async (appContext) => {
// 	const { ctx } = appContext;
// 	const pageProps = App.getInitialProps(appContext);

// 	// Use X-Forwarded-Proto header if available, otherwise check if the connection is secure
// 	const proto = ctx.req
// 		? ctx.req.headers['x-forwarded-proto'] || (ctx.req.connection.encrypted ? 'https' : 'http')
// 		: 'http';

// 	// Define the base URL depending on whether the code is running server-side or client-side
// 	const baseUrl = ctx.req ? `${proto}://${ctx.req.headers.host}` : '';

// 	console.log('baseUrl : ', baseUrl);

// 	let brandSettings = {};
// 	try {
// 		const res = await fetch(`${baseUrl}/data/brandSettings.json`);
// 		console.log('res : ', res);
// 		if (res.ok) {
// 			brandSettings = await res.json();
// 		} else {
// 			console.error('Failed to fetch brand settings:', res.status);
// 		}
// 	} catch (error) {
// 		console.error('Error fetching brand settings:', error);
// 	}

// 	return { ...pageProps, brandSettings };
// };

// export default MyApp;

export default appWithI18Next(MyApp, nexti18nConfig);

// export function reportWebVitals(metric) {
// 	console.log(metric); // You can use any logging solution here

// 	// Example of conditional logging
// 	if (metric.label === 'web-vital') {
// 		console.log(metric); // Log web vitals
// 		// You can send to analytics backend or monitoring service here
// 	}
// }
