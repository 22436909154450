/* eslint-disable quotes */
export const handleApiError = (errorData) => {
	switch (errorData.code) {
		case 1:
			return 'Authentication problem. Please log in again.';
		case 2:
			return "There's an issue with the request body. Please check your input.";
		// Add more cases as needed
		default:
			return errorData.message || 'An unexpected error occurred.';
	}
};
