import { useSyncLanguage } from 'ni18n';
import { useEffect } from 'react';
import brandSettings from '../public/data/brandSettings.json';

export const useLanguageHook = () => {
	const lastLanguage = typeof window !== 'undefined' && window.localStorage.getItem('lang');

	const locale = lastLanguage ? lastLanguage : brandSettings?.defaultLanguageCode;
	useSyncLanguage(locale ?? 'id-ID');

	useEffect(() => {
		const hasLanguageSetting = window.localStorage.getItem('lang');

		if (!hasLanguageSetting) {
			// Set the default language or get from the browser
			const defaultLanguage = brandSettings?.defaultLanguageCode || 'id-ID'; // Example: 'en', 'de', etc.
			window.localStorage.setItem('lang', defaultLanguage);
		}
	}, []);
	return {};
};
