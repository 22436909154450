import { fetchData } from '../fetchData';

const GetCountries = async () => {
	try {
		const response = await fetchData(null, 'country/get');

		return response;
	} catch (error) {
		throw error?.data;
	}
};

const TransferGameService = async (payload) => {
	try {
		const response = await fetchData(payload, 'game');

		return response;
	} catch (error) {
		throw error?.data;
	}
};

const GetCurrencies = async (payload) => {
	try {
		const response = await fetchData(payload, 'currency-lists');
		return response;
	} catch (error) {
		throw error?.data;
	}
};

const GetBankList = async (payload) => {
	try {
		const response = await fetchData(payload, 'paymentChannel');
		return response;
	} catch (error) {
		throw error?.data;
	}
};

const Constant = {
	GetCountries,
	GetCurrencies,
	TransferGameService,
	GetBankList,
};

export default Constant;
