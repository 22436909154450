import { useEffect, useMemo, useState } from 'react';

const usePersistentState = (init, key) => {
	const storedValue = (() => (typeof window !== 'undefined' ? localStorage.getItem(key) : null))();

	const initialValue = storedValue ? JSON.parse(storedValue) : init;
	const [persistentState, setPersistentState] = useState(null);

	useMemo(() => {
		setPersistentState(initialValue);
	}, [initialValue]);

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(persistentState));
	}, [persistentState, key]);

	return {
		persistentState,
		setPersistentState,
	};
};

export default usePersistentState;
