import localStorage from 'localStorage';

const set = (key, value) => {
	if (!key || value === undefined) {
		return;
	}

	if (typeof value === 'object' && value !== null) {
		value = JSON.stringify(value);
	}

	localStorage.setItem(key, value);
};

const setMap = (key, map) => {
	const mapAsArray = Array.from(map);
	localStorage.setItem(key, JSON.stringify(mapAsArray));
};

const get = (key) => {
	if (!key) {
		return null;
	}

	const value = localStorage.getItem(key);

	try {
		return JSON.parse(value);
	} catch (e) {
		return value;
	}
};

const getMap = (key) => {
	const mapAsArray = JSON.parse(localStorage.getItem(key));
	return new Map(mapAsArray);
};

const remove = (key) => {
	if (!key) {
		return;
	}
	localStorage.removeItem(key);
};

const clearAll = () => {
	localStorage.clear();
};

function getAuthStorage() {
	return JSON.parse(localStorage.getItem('auth-storage'))?.state ?? {};
}

function getLoginToken() {
	return getAuthStorage().loginToken ?? null;
}

function getSessionToken() {
	return getAuthStorage().sessionToken ?? null;
}

function getFingerprint() {
	return getAuthStorage().fingerprint ?? null;
}

const localStorageUtils = {
	set,
	setMap,
	get,
	getMap,
	remove,
	clearAll,
	getLoginToken,
	getSessionToken,
	getFingerprint
};

export default localStorageUtils;
