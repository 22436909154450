import HttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

const supportedLngs = [
	'en-US',
	'es-ES',
	'fr-FR',
	'hi-IN',
	'id-ID',
	'it-IT',
	'ja-JP',
	'ko-KR',
	'ms-MY',
	'pt-PT',
	'ru-RU',
	'th-TH',
	'tr-TR',
	'vi-VN',
	'zh-CN'
];

const isBrowser = typeof window !== 'undefined';

export const nexti18nConfig = {
	react: { useSuspense: false },
	supportedLngs,
	ns: ['translation'],
	defaultLocale: 'id-ID', // Specify your default language here
	fallbackLng: 'id-ID', // Fallback language if the user's language is not supported
	use: isBrowser ? [ChainedBackend] : undefined,
	backend: isBrowser
		? {
				backends: [LocalStorageBackend, HttpBackend],
				backendOptions: [
					{
						expirationTime: 1 * 60 * 60 * 1000
					},
					{
						loadPath: '/locales/{{lng}}/{{ns}}.json'
					}
				]
		  }
		: undefined
};
