'use client';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(
	persist(
		(set) => ({
			sessionToken: null, // Persisted
			loginToken: null, // Persisted
			fingerprint: null, // Persisted
			isSessionValid: false, // Not persisted
			initializeStore: (loginToken, sessionToken, fingerprint) => {
				set((state) => ({
					...state,
					loginToken: loginToken,
					sessionToken: sessionToken,
					fingerprint: fingerprint
				}));
			},
			login: (loginToken) => {
				set((state) => ({ ...state, loginToken: loginToken }));
			},
			register: (loginToken) => {
				set((state) => ({ ...state, loginToken: loginToken }));
			},
			logout: () => {
				set((state) => ({ ...state, loginToken: null }));
			},
			validationSession: () => set((state) => ({ ...state, isSessionValid: true }))
		}),
		{
			name: 'auth-storage',
			partialize: (state) => ({
				sessionToken: state.sessionToken,
				loginToken: state.loginToken,
				fingerprint: state.fingerprint
			})
		}
	)
);

export default useAuthStore;
